<template>
  <div class="swiper swiper-container">
    <div class="swiper-wrapper">
      <!-- <div class="swiper-slide" @click="openService"><img src="/static/img/banner-20240618.jpg"/></div> -->
      <div class="swiper-slide" @click="openService"><img src="/static/img/img2.jpg" /></div>
      <div class="swiper-slide" @click="openService"><img src="/static/img/banner-dd.jpg" /></div>
      <!-- <div class="swiper-slide" @click="openService"><img src="/static/img/img1.jpg" /></div> -->
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script lang="ts">
declare let Swiper: any;
export default {
  mounted() {
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,//1秒切换一次
      },
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
    })
  }
}
</script>
<style lang="less" scoped>
.swiper {
  // height: 332px;
  width: 100%;
}

.swiper-slide img {
  width: 100%;
  display: block;
}
</style>
