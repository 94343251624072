
import SolutionItem from './SolutionItem.vue'
import router from '@/router';
export default {
  components:{
    SolutionItem
  },
  data() {
    return {
      list:[
        {img:'/static/img/j2.jpg',name:'数智协同·云钉',remark:'企业组织协同与业务融合解决方案',to:'/solution/synergy'},
        {img:'/static/img/j1.jpg',name:'数智决策·云图',remark:'企业数据价值发现与创新解决方案',to:'/solution/planning'},
        {img:'/static/img/j3.jpg',name:'数智人资·云效',remark:'企业组织人效管理与创新解决方案',to:'/solution/member'},
      ]
    }
  },
  methods:{
    goDetail(to:String){
      if(to){
        router.push(`${to}`);
      }
    }
  }
}
