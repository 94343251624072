
declare let Swiper: any;
export default {
  mounted() {
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 5000,//1秒切换一次
      },
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
    })
  }
}
