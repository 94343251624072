<template>
  <div class="solutions">
    <h3>解决方案  · solutions</h3>
    <p>一套聪明好用的日常工具，包含项目、待办、网盘、文档、日历等丰富应用，帮助你把想法变成现实，使用起来爱不释手。</p>
    <div class="solutions-list">
      <SolutionItem v-for="(item,index) in list" :key="index" :item="item" @detail="goDetail"/>
    </div>
  </div>
</template>
<script lang="ts">
import SolutionItem from './SolutionItem.vue'
import router from '@/router';
export default {
  components:{
    SolutionItem
  },
  data() {
    return {
      list:[
        {img:'/static/img/j2.jpg',name:'数智协同·云钉',remark:'企业组织协同与业务融合解决方案',to:'/solution/synergy'},
        {img:'/static/img/j1.jpg',name:'数智决策·云图',remark:'企业数据价值发现与创新解决方案',to:'/solution/planning'},
        {img:'/static/img/j3.jpg',name:'数智人资·云效',remark:'企业组织人效管理与创新解决方案',to:'/solution/member'},
      ]
    }
  },
  methods:{
    goDetail(to:String){
      if(to){
        router.push(`${to}`);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.solutions{
  background-color: #172146;
  padding: 50px 10px 45px;
  >h3{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
  >p{
    font-size: 15px;
    color: #fff;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    padding: 0 15px;
    text-align: justify;
  }
  & &-list{
    margin-top: 35px;
  }
}
</style>
