<template>
  <div class="cloud-container">
    <!-- <Coupons/> -->
    <CloudItemBox title="阿里云热销产品 · HOT SALE" desc="官方渠道购买  /  专家顾问服务  /  更多优惠折扣" :list="list"/>
  </div>
</template>
<script lang="ts">
import CloudItemBox from './CloudItemBox.vue';
import Coupons from './Coupons.vue';
export default {
  components:{
    CloudItemBox,
    Coupons
  },
  data() {
    return {
      list:[
        {title:'弹性计算',name:'云服务器 ECS',remark:'从安全型到内存型、从进阶型到入门型的云服务器',price:'￥1992.00/6月起'},
        {title:'数据库',name:'云数据库 POLARDB',remark:'阿里巴巴自主研发的下一代关系型分布式云原生数据库',price:'￥2937.60/年起'},
        {title:'存储服务',name:'对象存储 OSS',remark:'适合图片/音视频等多媒体数据存储，数据实时处理，海量存储无上限',price:'￥99.00/年起'},
        {title:'云通信',name:'短信服务',remark:'支持国内验证码、短信通知和推广短信，秒级触达，免运维',price:'￥0.036/条起'},
        {title:'云安全',name:'WEB应用防火墙',remark:'防注入，防CC，一站式WEB安全解决方案',price:'￥3880.00/月起'},
        {title:'大数据',name:'DATAV数据可视化',remark:'通过图形化的界面轻松搭建专业水准的可视化应用',price:'￥475.00/月起'},
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.cloud-container{
  position: relative;
  background-color: #f7f7f7;
  &::after{
    content: '';
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    bottom: 30px;
    background: linear-gradient(0deg, #284CB9, #9A0000);
    z-index: -1;
  }
}
</style>
