<template>
  <div class="cloud-item">
    <div class="cloud-item-head">{{ title }}</div>
    <div class="cloud-item-desc">{{ desc }}</div>
    <div class="cloud-item-body clearfix">
      <Item v-for="(item, index) in list" :key="index" :item="item" />
    </div>
  </div>
</template>
<script lang="ts">
import Item from './Item.vue';
export default {
  components: {
    Item
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="less" scoped>
.cloud-item {
  overflow: hidden;
  padding: 30px 0 40px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .cloud-item-head {
    // margin: auto;
    // width: 94vw;
    // height: 28.4vw;
    // background: url(../../assets/index/back_6.png) no-repeat center;
    // background-size: cover;
    // padding: 5.33vw 4vw 10.66vw;
    // text-align: center;
    // font-size: 24px;
    // color: #fff;
    // font-weight: bold;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #383838;
    margin-bottom: 10px;
  }

  .cloud-item-desc {
    font-size: 14px;
    text-align: center;
    color: #383838;
    margin-bottom: 25px;
  }

  .cloud-item-body {
    margin: auto -5px;
    // width: 94vw;
    box-sizing: border-box;
    // border: 5px solid #FF3044;
    // border-radius: 20px;
    // background-color: #FFE7C5;
    // padding: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }
}
</style>
