<template>
  <div class="solution-item" @click="$emit('detail',item.to)">
    <img :src="item.img" />
    <div class="remark">
      <b>{{ item.name }}</b>
      &nbsp;&nbsp;/&nbsp;&nbsp;
      <span>{{ item.remark }}</span>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="less" scoped>
.solution-item {
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  position: relative;
  > img {
    width: 100%;
    display: block;
  }
  .remark {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    align-items: center;
    overflow: hidden;
    > b {
      font-weight: bold;
    }
    > span {
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
