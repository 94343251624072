<template>
  <div class="dingtalk">
    <h3>钉钉 · 新生产力工具</h3>
    <p>5亿用户，1900万组织，都在用钉钉。为您的企业提供强大的组织、沟通、协同、业务和生态在线的数字化办公能力。</p>
    <ul>
      <li class="clearfix">
        <label>专业</label>
        <div>
          <div class="dd-title">钉钉专业版</div>
          <div class="dd-remark">助力企业打造属于自己的数字化资产</div>
        </div>
      </li>
      <li class="clearfix">
        <label>专属</label>
        <div>
          <div class="dd-title">钉钉专属版</div>
          <div class="dd-remark">助力企业打造专属、安全、开放的数字化办公运营平台</div>
        </div>
      </li>
      <li class="clearfix">
        <label>专有</label>
        <div>
          <div class="dd-title">钉钉专有版</div>
          <div class="dd-remark">助力大型政企搭建专有数字化协同平台</div>
        </div>
      </li>
    </ul>
    <div class="btn-list">
      <button @click="openService" v-if="$route.path != '/cloud/dingtalk'">立即咨询</button>
      <button @click="openService" :style="{ flex: 1, width: 'auto', margin: 'auto', color: '#fff' }"
        v-else>立即咨询了解更多详情</button>
      <button @click="$router.push('/cloud/dingtalk')" v-if="$route.path != '/cloud/dingtalk'">了解详情</button>
    </div>
  </div>
</template>
<script></script>
<style lang="less" scoped>
.dingtalk {
  padding: 60px 20px;
  background: url(../../assets/index/back_7.jpg) no-repeat center;
  background-size: cover;

  >h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }

  >p {
    font-size: 15px;
    color: #fff;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    text-align: justify;
  }

  >ul>li {
    margin-bottom: 27px;
    display: flex;

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    >img {
      width: 57px;
      height: 57px;
      display: block;
      float: left;
      margin-right: 20px;
    }

    >label {
      flex: none;
      width: 57px;
      height: 57px;
      display: block;
      float: left;
      margin-right: 20px;
      border-radius: 100%;
      border: 2px solid white;
      box-sizing: border-box;
      line-height: 53px;
      text-align: center;
      color: white;
    }

    .dd-title {
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      line-height: 1.8;
    }

    .dd-remark {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      line-height: 2;
    }
  }

  .btn-list {
    display: flex;
    margin-top: 50px;

    >button {
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>
