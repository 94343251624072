<template>
  <div class="item">
    <div class="item-title">{{ item.title }}</div>
    <div class="item-box">
      <h3>{{ item.name }}</h3>
      <p>{{ item.remark }}</p>
      <span>{{ item.price }}</span>
      <button @click="openService">获取折扣</button>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style lang="less">
.item {
  --item-padding: 5px;
  padding: var(--item-padding);
  width: 50%;
  position: relative;

  // background: #FFE7C5;
  &::before {
    content: '';
    position: absolute;
    top: var(--item-padding);
    left: var(--item-padding);
    bottom: var(--item-padding);
    right: var(--item-padding);
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: white;
  }

  & &-title {
    // background-color: #D60103;
    font-size: 15px;
    color: #383838;
    font-weight: bold;
    line-height: 35px;
    text-align: center;
    font-family: Source Han Sans CN;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    position: relative;
  }

  & &-box {
    padding: 10px;
    // background-color: #5C0001;
    font-family: Source Han Sans CN;
    position: relative;

    >h3 {
      font-size: 14px;
      font-weight: bold;
      color: #656565;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    >p {
      font-size: 13px;
      font-weight: 300;
      color: #656565;
      margin-top: 10px;
      height: 55px;
      line-height: 1.5;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-align: justify;
    }

    >span {
      display: block;
      color: #D60103;
      font-size: 15px;
      font-weight: bold;
      margin: 10px auto;
      text-decoration: line-through;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    >button {
      display: block;
      appearance: none;
      background: none;
      border: 1px solid;
      border-color: #E80B10;
      border-radius: 3px;
      line-height: 30px;
      width: 100%;
      font-size: 16px;
      color: #E80B10;
      font-weight: 500;
    }
  }
}
</style>
