<template>
  <div class="container footer-placeholder">
    <!-- 首页轮播 -->
    <Swiper/>
    <!-- 产品服务 -->
    <Cloud/>
    <!-- 产品 -->
    <CloudItem/>
    <!-- DingTalk -->
    <!-- <div style="margin-top:-30px">
    </div> -->
    <DingTalk/>
    <!-- Teambition -->
    <Teambition/>
    <!-- Solution -->
    <Solution/>
  </div>
  <Footer/>
</template>
<script lang="ts">
import Swiper from '@/components/home/Swiper.vue';
import Cloud from '@/components/home/Cloud.vue';
import CloudItem from '@/components/home/CloudItem.vue';
import DingTalk from '@/components/home/DingTalk.vue';
import Teambition from '@/components/home/Teambition.vue';
import Solution from '@/components/home/Solution.vue';
import Footer from '@/components/Footer.vue'
export default {
  data () {
    return {
      title:'Index'
    }
  },
  components:{
    Swiper,
    Cloud,
    CloudItem,
    DingTalk,
    Teambition,
    Solution,
    Footer
  },
}
</script>